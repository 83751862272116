import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NonNullableLDEvaluationReason } from 'launchdarkly-js-client-sdk';
import { Dict } from 'mixpanel-browser';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AnalyticsService, FLOW } from '@app/core/analytics.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags, FeatureFlagVariants, HomeRedesignVariation } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import {
  APPOINTMENT_CANCEL_CLICKED,
  APPOINTMENT_CANCELLED,
  APPOINTMENT_DIRECTIONS_CLICKED,
  APPOINTMENT_MARKED_AS_RUNNING_LATE,
  APPOINTMENT_RESCHEDULE_CLICKED,
  BOTTOM_GROWTH_BANNER,
  DOWNLOAD_APP_CLICKED,
  EVENT_BANNER_CLICKED,
  EVENT_BOOK_VISIT_CLICKED,
  EVENT_TREAT_ME_NOW_MODAL_CLOSED,
  FLOW_ACCOUNT_UPDATE,
  FLOW_APPOINTMENT_BOOKING,
  FLOW_HOME_PAGE_NAVIGATION,
  FLOW_MESSAGES,
  FSA_REIMBURSEMENT_CLICKED,
  JOIN_REMOTE_VISIT_CLICKED,
  MANAGE_PRESCRIPTIONS_CLICKED,
  MESSAGES_CLICKED,
  MOBILE_ENGAGEMENT,
  MOBILE_ENGAGEMENT_MODAL_CLOSED,
  MODULE_HOME_PAGE,
  MODULE_PCP_SELECTION_PAGE,
  MODULE_PRESCRIPTION_RENEWAL_PAGE,
  MP_EVENT_EMAIL_VERIFICATION_SENT,
  MP_EVENT_PAGE_VIEWED,
  PRESCRIPTION_MANAGEMENT,
  REMINDER_CLICKED,
  RUNNING_LATE_APPOINTMENT_CLICKED,
  SEND_DOWNLOAD_LINK_CLICKED,
  SKIP_DOWNLOAD_LINK_CLICKED,
  SUBMODULE_BOOK_VISIT_BUTTON,
  SUBMODULE_CARE_TEAM_MESSAGES_BUTTON,
  SUBMODULE_COVID_RESOURCES,
  SUBMODULE_EMAIL_VERIFICATION_CAROUSEL_CARD,
  TRAVEL_RECOMMENDATIONS_CLICKED,
  TREAT_ME_NOW_CLICKED,
  VIDEO_CHAT_CLICKED,
  VIEW_APPOINTMENT_DETAILS_CLICKED,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { BillingInsuranceAnalyticsProps } from '@app/home-redesign/billing-and-insurance/billing-and-insurance.component';
import { CareTeamAnalyticsProps } from '@app/home-redesign/care-team/care-team.component';
import { HomeAnalyticsProps } from '@app/home-redesign/home.component';
import { PromotionalBannerAnalyticsProps } from '@app/home-redesign/promotional-banner/promotional-banner.component';
import { VirtualWelcomeHeroAnalyticsProps } from '@app/home-redesign/virtual-welcome-hero/virtual-welcome-hero.component';
import { Reminder } from '@app/home-redesign/whats-next/reminder-list/reminder';
import { WhatsNextAnalyticsProps } from '@app/home-redesign/whats-next/whats-next.component';
import { Appointment_appointment } from '@app/home/__generated__/Appointment';
import { Reminders_patient_notifications_notifiableType_reminderActions } from '@app/home/__generated__/Reminders';
import { AppointmentTypeInfoForAnalyticsGraphQLService } from '@app/home/appointment-type-info-for-analytics-graphql.service';

import { State } from '../app.reducer';

export interface TrackPageViewProps {
  source?: string;
  is_modal_shown: boolean;
  modal_type?: string;
}

export enum HomeScreenCardClickType {
  Image = 'Image',
  Dismiss = 'Dismiss',
  SignUp = 'Sign Up',
  LearnMore = 'Learn More',
}

export enum OnboardingItemAnalyticsProperty {
  VerifyEmail = 'verify_your_email_address',
  HealthRecords = 'connect_your_health_records',
  SelectPcp = 'select_a_primary_care_provider',
}

export interface HomePageItemsShownProps
  extends HomeAnalyticsProps,
    CareTeamAnalyticsProps,
    WhatsNextAnalyticsProps,
    PromotionalBannerAnalyticsProps,
    BillingInsuranceAnalyticsProps,
    VirtualWelcomeHeroAnalyticsProps {}

@Injectable({
  providedIn: 'root',
})
export class HomePageAnalyticsService extends AnalyticsService {
  protected readonly module = MODULE_HOME_PAGE;
  private readonly cardSubmodule = 'Card';
  private readonly coronavirusCardSubmodule = 'Coronavirus Card';
  private readonly heroSubmodule = 'Hero';
  private readonly cardDisplayed = 'Card Displayed';
  private readonly cardDismissed = 'Card Dismissed';
  private readonly onboardingCarouselDisplayed = 'Onboarding Carousel Displayed';
  private readonly onboardingCarouselCardClicked = 'Onboarding Carousel Card Clicked';

  constructor(
    private featureFlagSelectors: FeatureFlagSelectors,
    protected mixpanelService: MixpanelService,
    protected store: Store<State>,
    protected launchDarklyService: LaunchDarklyService,
    private appointmentTypeInfoForAnalyticsGraphQLService: AppointmentTypeInfoForAnalyticsGraphQLService,
    protected router?: Router,
  ) {
    super(mixpanelService, store, launchDarklyService, router);
  }

  getModule(): string {
    return this.module;
  }

  trackInAppVirtualExperienceVariationAssigned(
    experimentName: FeatureFlags,
    experimentVariation: FeatureFlagVariants,
    evaluationReason: NonNullableLDEvaluationReason['kind'],
  ): Observable<unknown> {
    return this.trackWithDefaultProperties('In-App Virtual Experience Variation Assigned', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      module: MODULE_HOME_PAGE,
      experiment_name: experimentName,
      experiment_variation_name: experimentVariation,
      feature_flag_eval_reason: evaluationReason,
    });
  }

  trackReviewBillsClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Review Bills Clicked', {
      flow: 'External Payment Portal',
    });
  }

  trackInsuranceClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Insurance Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
    });
  }

  trackDirectSignupStarted(): Observable<unknown> {
    return super.trackDirectSignupStarted(BOTTOM_GROWTH_BANNER);
  }

  trackReferralModalOpen(): Observable<unknown> {
    return super.trackReferralModalOpen(this.module, BOTTOM_GROWTH_BANNER);
  }

  trackMessageYourPcpClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Messages Clicked', {
      flow: 'Messages',
      submodule: 'Care Team Messages Button',
    });
  }

  trackMessageCareTeamClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Messages Clicked', {
      flow: FLOW.PATIENT_TASKS,
      module: MODULE_HOME_PAGE,
    });
  }

  trackSeePastVisitsClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('View Past Visits Clicked', {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Care Team See Past Visits Button',
    });
  }

  trackViewProvidersClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Choose Provider Clicked', {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Care Team View Providers Button',
    });
  }

  trackScheduleWithPcpClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Care Team Schedule Button',
    });
  }

  trackHomePageItemsShown(properties: HomePageItemsShownProps) {
    this.trackWithDefaultProperties('Web Homepage Items Shown', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      ...properties,
    });
  }

  trackAppointmentCancellationStarted(appointmentId: string) {
    combineLatest([
      this.appointmentTypeDisplayName$(appointmentId),
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.VEX_SWITCH_TO_REMOTE),
    ])
      .pipe(
        switchMap(([appointmentTypeDisplayName, variation]) =>
          this.trackWithDefaultProperties(APPOINTMENT_CANCEL_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
            experiment_name: FeatureFlags.VEX_SWITCH_TO_REMOTE,
            experiment_variation_name: variation ? 'ON VARIANT' : 'ON CONTROL',
          }),
        ),
      )
      .subscribe();
  }

  trackAppointmentCancellationConfirmed(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(APPOINTMENT_CANCELLED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackAppointmentRescheduleStarted(appointmentId: string) {
    combineLatest([
      this.appointmentTypeDisplayName$(appointmentId),
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.VEX_SWITCH_TO_REMOTE),
    ])
      .pipe(
        switchMap(([appointmentTypeDisplayName, variation]) =>
          this.trackWithDefaultProperties(APPOINTMENT_RESCHEDULE_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
            experiment_name: FeatureFlags.VEX_SWITCH_TO_REMOTE,
            experiment_variation_name: variation ? 'ON VARIANT' : 'ON CONTROL',
          }),
        ),
      )
      .subscribe();
  }

  trackOnboardingTaskClicked(onboardingItem: OnboardingItemAnalyticsProperty) {
    return this.trackWithDefaultProperties('Onboarding Task Clicked', {
      flow: 'Onboarding',
      onboarding_item: onboardingItem,
    });
  }

  trackListCardLink(eventName: string) {
    return this.trackWithDefaultProperties(eventName, {
      flow: 'Member Request',
      submodule: this.cardSubmodule,
    });
  }

  trackChoosePcpLink() {
    return this.trackWithDefaultProperties('Page Viewed', {
      flow: 'PCP Selection',
      module: MODULE_PCP_SELECTION_PAGE,
      source: 'MyOne Homepage',
    });
  }

  trackEditPcpLink() {
    return this.trackWithDefaultProperties('Edit PCP Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
      submodule: this.cardSubmodule,
    });
  }

  trackHeroBookVisitLink() {
    return super.trackBookVisitLink({
      module: this.module,
      submodule: this.heroSubmodule,
    });
  }

  trackHealthRecordLink() {
    return super.trackHealthRecordLink({
      module: this.module,
      submodule: this.cardSubmodule,
    });
  }

  trackPreferredPharmacyLink(_querySelector: string) {
    return this.trackWithDefaultProperties('Preferred Pharmacy Clicked', {
      flow: 'Prescription Renewal',
      submodule: this.cardSubmodule,
    });
  }

  trackRequestPrescriptionRenewalLink() {
    return this.trackWithDefaultProperties('Request Prescription Renewal Clicked', {
      flow: 'Prescription Renewal',
      submodule: this.cardSubmodule,
    });
  }

  trackLegacyHomePageViewed(props: TrackPageViewProps) {
    const homeScreenRedesignExperimentProperties = {
      experiment_name: FeatureFlags.HOME_SCREEN_REDESIGN,
      experiment_variant_name: HomeRedesignVariation.OFF,
    };

    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      ...props,
      ...homeScreenRedesignExperimentProperties,
    });
  }

  trackHomePageViewed(props: TrackPageViewProps): void {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      experiment_name: FeatureFlags.HOME_SCREEN_REDESIGN,
      experiment_variant_name: HomeRedesignVariation.ON_ALTERNATE_TEXT,
      ...props,
    });
  }

  trackMindsetProgramPromotionCardDisplayed() {
    return this.trackWithDefaultProperties('Enterprise Mindset Promo Card Displayed', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
    });
  }

  trackMindsetProgramPromotionCardClicked({ clickType }: { clickType: HomeScreenCardClickType }) {
    return this.trackWithDefaultProperties('Enterprise Mindset Promo Card Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      click_type: clickType,
    });
  }

  trackStressManagementCardDisplayed() {
    return this.trackWithDefaultProperties('Stress & Anxiety Program Promo Card Displayed', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: 'Stress & Anxiety Program Promo Card',
    });
  }

  trackStressManagementCardClicked({ clickType }: { clickType: HomeScreenCardClickType }) {
    return this.trackWithDefaultProperties('Stress & Anxiety Program Promo Card Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: 'Stress & Anxiety Program Promo Card',
      click_type: clickType,
    });
  }

  trackDownloadAppLinkSent() {
    this.trackWithDefaultProperties('App Link Sent', {
      submodule: this.cardSubmodule,
      flow: MOBILE_ENGAGEMENT,
    });
  }

  trackDownloadAppClicked() {
    return this.trackWithDefaultProperties(DOWNLOAD_APP_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
    });
  }

  trackCoronavirusDownloadAppLinkSent() {
    this.trackWithDefaultProperties('App Link Sent', {
      submodule: this.coronavirusCardSubmodule,
      flow: MOBILE_ENGAGEMENT,
      source: 'Coronavirus Card',
    });
  }

  sharePcpStarted() {
    this.trackWithDefaultProperties('Share PCP Started', {
      flow: 'Share PCP',
      module: 'Share PCP Modal',
    });
  }

  sharePcpSubmitted() {
    this.trackWithDefaultProperties('Share PCP Submitted', {
      flow: 'Share PCP',
      module: 'Share PCP Modal',
    });
  }

  trackReminderCardDisplayed(appointment: Appointment_appointment) {
    this.trackWithDefaultProperties('Card Displayed', {
      appointment_id: appointment.id,
      appointment_type: appointment.appointmentType.displayName,
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Upcoming Appointment Card',
    });
  }

  trackReminderActionClicked(
    action: Reminders_patient_notifications_notifiableType_reminderActions,
    appointment: Appointment_appointment,
  ): Observable<void> {
    return this.trackWithDefaultProperties(action.event, {
      appointment_id: appointment.id,
      appointment_type: appointment.appointmentType.displayName,
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Upcoming Appointment Card',
    });
  }

  trackReminderClicked(reminder: Reminder): Observable<void> {
    return this.trackWithDefaultProperties(REMINDER_CLICKED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      module: MODULE_HOME_PAGE,
      reminder_type: reminder.type,
      task_type: reminder.subType ?? null,
    });
  }

  trackSleepCardDisplayed() {
    const properties = {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Sleep Card',
    };
    this.trackWithDefaultProperties(this.cardDisplayed, properties);
  }

  trackSleepCardDismissed() {
    const properties = {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Sleep Card',
    };
    this.trackWithDefaultProperties(this.cardDismissed, properties);
  }

  trackBookPhysicalSleepCardClicked() {
    return this.trackWithDefaultProperties('Book Physical on Sleep Card Clicked', {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Sleep Card',
      source: 'Sleep Card',
    });
  }

  trackDownloadAppCardDisplayed() {
    const properties = {
      flow: MOBILE_ENGAGEMENT,
      submodule: 'App Download Card',
    };
    this.trackWithDefaultProperties(this.cardDisplayed, properties);
  }

  trackDownloadAppCardDismissed() {
    const properties = {
      flow: MOBILE_ENGAGEMENT,
      submodule: 'App Download Card',
    };
    this.trackWithDefaultProperties(this.cardDismissed, properties);
  }

  trackReturnToWorkDailyCheckInDisplayed() {
    const properties = {
      flow: 'Healthy Together Check-In Survey',
      submodule: 'Healthy Together Check-In Card',
    };
    this.trackWithDefaultProperties(this.cardDisplayed, properties);
  }

  trackReturnToWorkDailyCheckInClicked() {
    return this.trackWithDefaultProperties('Survey Loaded', {
      flow: 'Healthy Together Check-In Survey',
      source: 'Healthy Together Check-In Card',
      survey_id: 27,
    });
  }

  trackAfterVisitSummariesDownloadAppModal() {
    const properties = {
      flow: 'After Visit Summaries',
      submodule: 'App Download Card',
    };

    this.trackWithDefaultProperties(this.cardDisplayed, properties);
  }

  trackOnboardingCarouselDisplayed() {
    const properties = {
      flow: 'Onboarding',
    };
    this.trackWithDefaultProperties(this.onboardingCarouselDisplayed, properties);
  }

  trackOnboardingCarouselClicked(submodule: string) {
    const properties = {
      flow: 'Onboarding',
      submodule,
    };
    this.trackWithDefaultProperties(this.onboardingCarouselCardClicked, properties);
  }

  trackEmailVerificationCodeSent() {
    const properties = {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: SUBMODULE_EMAIL_VERIFICATION_CAROUSEL_CARD,
    };
    this.trackWithDefaultProperties(MP_EVENT_EMAIL_VERIFICATION_SENT, properties);
  }

  trackTreatMeNowModalClosed() {
    this.trackWithDefaultProperties(EVENT_TREAT_ME_NOW_MODAL_CLOSED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
    });
  }

  trackCovidBannerClicked() {
    this.trackWithDefaultProperties(EVENT_BANNER_CLICKED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: SUBMODULE_COVID_RESOURCES,
    });
  }

  trackBookVisitClicked(submodule: string): Observable<void> {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
      submodule: submodule,
    });
  }

  trackBookVisitWhileTravelingClicked(): Observable<void> {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
      submodule: SUBMODULE_BOOK_VISIT_BUTTON,
      source_section_label: 'Schedule A Visit While Traveling',
    });
  }

  trackCareTeamMessageClicked() {
    return this.trackWithDefaultProperties(MESSAGES_CLICKED, {
      flow: FLOW_MESSAGES,
      module: MODULE_HOME_PAGE,
      submodule: SUBMODULE_CARE_TEAM_MESSAGES_BUTTON,
    });
  }

  trackPrescriptionRenewalClicked(): Observable<void> {
    return this.trackWithDefaultProperties(MANAGE_PRESCRIPTIONS_CLICKED, {
      flow: PRESCRIPTION_MANAGEMENT,
      module: MODULE_PRESCRIPTION_RENEWAL_PAGE,
    });
  }

  trackTreatMeNowClicked(): Observable<void> {
    return this.trackWithDefaultProperties(TREAT_ME_NOW_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module: MODULE_HOME_PAGE,
    });
  }

  trackVideoChatClicked(): Observable<void> {
    return this.trackWithDefaultProperties(VIDEO_CHAT_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module: MODULE_HOME_PAGE,
    });
  }

  trackSendDownloadLinkClicked({ module, submodule }: { module: string; submodule?: string }): Observable<void> {
    return this.trackWithDefaultProperties(SEND_DOWNLOAD_LINK_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module,
      submodule,
    });
  }

  trackSkipDownloadLinkClicked({ submodule }: { submodule?: string } = {}): Observable<void> {
    return this.trackWithDefaultProperties(SKIP_DOWNLOAD_LINK_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module: MODULE_HOME_PAGE,
      submodule,
    });
  }

  trackDownloadAppModalClosed({ module }: { module: string }): Observable<void> {
    return this.trackWithDefaultProperties(MOBILE_ENGAGEMENT_MODAL_CLOSED, {
      flow: MOBILE_ENGAGEMENT,
      module,
    });
  }

  trackJoinRemoteVisitClicked(): Observable<void> {
    return this.trackWithDefaultProperties(JOIN_REMOTE_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
    });
  }

  trackAppointmentDirectionsClicked(): Observable<void> {
    return this.trackWithDefaultProperties(APPOINTMENT_DIRECTIONS_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
    });
  }

  trackViewAppointmentDetailsClicked(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(VIEW_APPOINTMENT_DETAILS_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            module: MODULE_HOME_PAGE,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackRunningLateAppointmentClicked(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(RUNNING_LATE_APPOINTMENT_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            module: MODULE_HOME_PAGE,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackAppointmentMarkedAsRunningLate(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(APPOINTMENT_MARKED_AS_RUNNING_LATE, {
            flow: FLOW_APPOINTMENT_BOOKING,
            module: MODULE_HOME_PAGE,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackTravelRecommendationsClicked() {
    return this.trackWithDefaultProperties(TRAVEL_RECOMMENDATIONS_CLICKED, {
      flow: FLOW.SURVEYS,
      module: MODULE_HOME_PAGE,
    });
  }

  trackFsaReimbursementClicked() {
    return this.trackWithDefaultProperties(FSA_REIMBURSEMENT_CLICKED, {
      flow: FLOW.SURVEYS,
      module: MODULE_HOME_PAGE,
    });
  }

  protected trackWithDefaultProperties(eventName: string, properties?: Dict): Observable<any> {
    if (!properties.module) {
      properties.module = this.module;
    }

    return super.trackWithDefaultProperties(eventName, properties);
  }

  private appointmentTypeDisplayName$(appointmentId: string): Observable<string> {
    return this.appointmentTypeInfoForAnalyticsGraphQLService
      .fetch({ id: appointmentId })
      .pipe(map(result => result.data.appointment.appointmentType.displayName));
  }
}
